import { render, staticRenderFns } from "./ChooseProducts.vue?vue&type=template&id=a205b138&scoped=true&"
import script from "./ChooseProducts.vue?vue&type=script&lang=js&"
export * from "./ChooseProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a205b138",
  null
  
)

export default component.exports