<template>
  <div>
    <!-- The modal -->
    <b-modal
      :id="'order-edit-modal-' + order.id"
      ref="modal"
      size="lg"
      title="Edytuj zamówienie"
      @show="initModal"
      @hide="resetModal"
      @cancel="resetModal"
      @ok="handleModalOk"
      ok-title="Edytuj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <div class="row mb-3">
        <div class="col-4">
          <b-input-group prepend="Adres do faktury" size="sm" class="mt-1">
            <b-form-select
              v-model="form.invoiceAddressSelect"
              :options="availableAddressList"
              size="sm"
            ></b-form-select>
          </b-input-group>

          <div class="text-center p-2">
            <strong>{{ form.invoiceAddress.name }}</strong
            ><br />
            {{ form.invoiceAddress.postalCode }} {{ form.invoiceAddress.city }}
            <br v-if="form.invoiceAddress.street !== ''" />
            {{ form.invoiceAddress.street }}
            {{ form.invoiceAddress.houseNumber }}
            {{ form.invoiceAddress.apartmentNumber }}<br />
            <span v-if="order.client.phone !== ''"> tel. </span
            >{{ order.client.phone }}
          </div>
        </div>

        <div class="col-4">
          <b-input-group prepend="Adres do dostawy" size="sm" class="mt-1">
            <b-form-select
              size="sm"
              v-model="form.shippingAddressSelect"
              :options="availableAddressList"
            ></b-form-select>
          </b-input-group>

          <div class="text-center p-2">
            <strong>{{ form.shippingAddress.name }}</strong
            ><br />
            {{ form.shippingAddress.postalCode }}
            {{ form.shippingAddress.city }}
            <br v-if="form.shippingAddress.street !== ''" />
            {{ form.shippingAddress.street }}
            {{ form.shippingAddress.houseNumber }}
            {{ form.shippingAddress.apartmentNumber }}<br />
            <span v-if="order.client.phone !== ''"> tel. </span
            >{{ order.client.phone }}
          </div>
        </div>

        <div class="col-4">
          <b-form-group prepend="Data zam." size="sm" class="mt-1">
            <b-form-datepicker
              reset-button
              type="date"
              placeholder="Data zamówienia"
              size="sm"
              :start-weekday="1"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              @input="handleChangeOrderDate($event)"
              :value="form.orderDate"
            >
            </b-form-datepicker>
          </b-form-group>
          <b-input-group prepend="Forma płatności" size="sm" class="mt-1">
            <b-form-select
              size="sm"
              id="paymentMethod"
              v-model="form.paymentMethod"
              :options="paymentMethodsList"
            ></b-form-select>
          </b-input-group>
          <b-input-group
            v-if="form.paymentMethod === 'transfer'"
            prepend="Dni na płatność"
            size="sm"
            class="mt-1"
          >
            <b-form-input
              size="sm"
              type="number"
              min="1"
              max="30"
              step="1"
              v-model="form.daysForPayment"
            ></b-form-input>
          </b-input-group>
          <b-form-group label-for="showAllProducts" class="mt-1">
            <b-form-checkbox id="showAllProducts" v-model="showAllProducts"
              >Pokaż wszystkie produkty</b-form-checkbox
            >
          </b-form-group>
        </div>
      </div>

      <div class="row mb-3" v-if="order.client.extra_note && order.client.extra_note.length > 0">
        <div class="col-12">
          <div class="text-center">
            <strong>Notatka dla zamówień:</strong><br>
            {{ order.client.extra_note }}
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-vertical-center table-sm table-striped">
          <thead class="thead-light">
            <tr class="d-flex">
              <th class="col-3">Nazwa produktu</th>
              <th class="col-1">Cena</th>
              <th class="col-2"></th>
              <th class="col-1">Ilość</th>
              <th class="col-1"></th>
              <th class="col-1">Rabat %</th>
              <th class="col-1"></th>
              <th class="col-2">Wartość</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in itemsInOrderSorted">
              <tr
                v-if="
                  (clientTypeCode === 'g' && item.category === 'golebiowa') ||
                  (clientTypeCode === 'g' &&
                    item.category === 'podstawowa' &&
                    showAllProducts) ||
                  (clientTypeCode === 'go' &&
                    item.category === 'golebiowa' &&
                    showAllProducts) ||
                  (clientTypeCode === 'go' && item.category === 'podstawowa') ||
                  (clientTypeCode === 'o' &&
                    item.category === 'golebiowa' &&
                    showAllProducts) ||
                  (clientTypeCode === 'o' && item.category === 'podstawowa')
                "
                :key="index"
                :class="[
                  { 'd-flex font-size-sm': true },
                  { 'text-danger': item.isSlim },
                ]"
                :style="'background-color: ' + getProductColor(item) + '!important'"
              >
                <td
                  class="col-3"
                  :class="[{ 'font-italic': item.category == 'golebiowa' }]"
                >
                  {{ item.name }} [ {{ item.capacity / 1000 }} {{ item.unit }}]
                </td>
                <td class="col-1 font-weight-bold">
                  {{ transformPrice(item.priceBrutto) }} zł
                </td>
                <td class="col-2"></td>
                <td class="col-1">
                  <b-form-input
                    type="number"
                    min="0"
                    step="1"
                    v-model="item.quantity"
                    @change="handleChangeItemQuantity($event, item.id)"
                    :class="[
                      { 'bg-secondary': item.quantity == 0 },
                      { 'font-weight-bold': item.quantity > 0 },
                    ]"
                    size="sm"
                    class="h-100 py-0"
                  ></b-form-input>
                </td>
                <td class="col-1"></td>
                <td class="col-1">
                  <b-form-input
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                    v-model="item.discount"
                    @change="handleChangeItemDiscount($event, item.id)"
                    size="sm"
                    class="h-100 py-0"
                    :class="[
                      { 'bg-secondary': item.discount == 0 },
                      { 'font-weight-bold': item.discount > 0 },
                    ]"
                  ></b-form-input>
                </td>
                <td class="col-1"></td>
                <td
                  class="col-2 text-dark"
                  :class="{ 'font-weight-bold': item.totalAfterDiscount > 0 }"
                >
                  {{ transformPrice(item.totalAfterDiscount) }} zł
                </td>
              </tr>
            </template>
          </tbody>
          <tr class="d-flex font-size-sm">
            <td class="col-12"></td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-1"></td>
            <td class="col-2 font-weight-bold">Suma Standard</td>
            <td
              class="col-2"
              :class="{ 'font-weight-bold': getOrderStandardTotal > 0 }"
            >
              {{ getOrderStandardTotal }} zł
            </td>
            <td class="col-1"></td>
            <td
              class="col-1"
              :class="{ 'font-weight-bold': getOrderStandardQuantitySum > 0 }"
            >
              {{ getOrderStandardQuantitySum }}
            </td>
            <td class="col-1"></td>
            <td
              class="col-2"
              :class="{
                'font-weight-bold': getOrderStandardTotalDiscountValue > 0,
              }"
            >
              {{ getOrderStandardTotalDiscountValue }} zł
            </td>
            <td
              class="col-2"
              :class="{
                'font-weight-bold': getOrderStandardTotalAfterDiscount > 0,
              }"
            >
              {{ getOrderStandardTotalAfterDiscount }} zł
            </td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12"></td>
          </tr>
          <tr class="d-flex font-size-sm text-danger">
            <td class="col-1"></td>
            <td class="col-2 font-weight-bold">Suma Slim</td>
            <td
              class="col-2"
              :class="{ 'font-weight-bold': getOrderSlimTotal > 0 }"
            >
              {{ getOrderSlimTotal }} zł
            </td>
            <td class="col-1"></td>
            <td
              class="col-1"
              :class="{ 'font-weight-bold': getOrderSlimQuantitySum > 0 }"
            >
              {{ getOrderSlimQuantitySum }}
            </td>
            <td class="col-1"></td>
            <td
              class="col-2"
              :class="{
                'font-weight-bold': getOrderSlimTotalDiscountValue > 0,
              }"
            >
              {{ getOrderSlimTotalDiscountValue }} zł
            </td>
            <td
              class="col-2"
              :class="{
                'font-weight-bold': getOrderSlimTotalAfterDiscount > 0,
              }"
            >
              {{ getOrderSlimTotalAfterDiscount }} zł
            </td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12"></td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-1"></td>
            <td class="col-2 font-weight-bold">Suma</td>
            <td
              class="col-2"
              :class="{ 'font-weight-bold': getOrderTotal > 0 }"
            >
              {{ getOrderTotal }} zł
            </td>
            <td class="col-1"></td>
            <td
              class="col-1"
              :class="{ 'font-weight-bold': getOrderQuantitySum > 0 }"
            >
              {{ getOrderQuantitySum }}
            </td>
            <td class="col-1"></td>
            <td
              class="col-2"
              :class="{ 'font-weight-bold': getOrderTotalDiscountValue > 0 }"
            >
              {{ getOrderTotalDiscountValue }} zł
            </td>
            <td
              class="col-2"
              :class="{ 'font-weight-bold': getOrderTotalAfterDiscount > 0 }"
            >
              {{ getOrderTotalAfterDiscount }} zł
            </td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12"></td>
          </tr>

          <tr class="d-flex font-size-sm">
            <td class="col-2">Opakowania:</td>
            <td class="col-6">
              {{ getBoxesCountAsText }}
            </td>
            <td class="col-4">
              <b-input-group size="sm" append="%">
                <b-input-group-prepend>
                  <b-button variant="primary" @click="setDiscount"
                    >Ustaw rabat</b-button
                  >
                </b-input-group-prepend>
                <b-form-input
                  type="number"
                  min="0"
                  max="100"
                  step="1"
                  v-model="form.setDiscountValue"
                  size="sm"
                ></b-form-input>
              </b-input-group>
            </td>
          </tr>

          <tr class="d-flex font-size-sm">
            <td class="col-4">
              <b-input-group prepend="Transport" size="sm" append="zł">
                <b-form-input
                  type="number"
                  min="0"
                  max="1000"
                  step="0.01"
                  :value="form.shippingCost"
                  @change="handleChangeShippingCost($event)"
                  size="sm"
                ></b-form-input>
              </b-input-group>
            </td>
            <td class="col-4">
              <b-input-group prepend="Transport Premium" size="sm" append="zł">
                <b-form-input
                  type="number"
                  min="0"
                  max="1000"
                  step="0.01"
                  :value="form.shippingPremiumCost"
                  @change="handleChangeShippingPremiumCost($event)"
                  size="sm"
                ></b-form-input>
              </b-input-group>
            </td>
            <td class="col-4">
              <b-input-group prepend="Transport Sobota" size="sm" append="zł">
                <b-form-input
                  type="number"
                  min="0"
                  max="1000"
                  step="0.01"
                  :value="form.shippingSaturdayCost"
                  @change="handleChangeShippingSaturdayCost($event)"
                  size="sm"
                ></b-form-input>
              </b-input-group>
            </td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12"></td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12">
              <b-input-group prepend="Notatki: " size="sm">
                <b-form-input
                  :value="form.note"
                  @change="handleChangeNote($event)"
                  size="sm"
                ></b-form-input>
              </b-input-group>
            </td>
          </tr>
          <tr class="d-flex font-weight-bold">
            <td class="col-7"></td>
            <td class="col-1"></td>
            <td class="col-1"></td>
            <td class="col-3 font-weight-bold h5 text-right">
              Razem: {{ form.priceTotal }} zł
            </td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12">
              <div class="mb-5">
                Obecne wgrany plik:
                <template v-if="form.filePath !== ''">
                  <a :href="getBaseURL + form.filePath" target="_blank"
                    >POBIERZ</a
                  >
                </template>
                <template v-else> BRAK </template>
              </div>
            </td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12">
              <b-form-group label-for="file">
                <b-form-file
                  id="file"
                  @change="handleChangeFile($event)"
                  accept=".pdf"
                  browse-text="Przeglądaj"
                  placeholder="Nie wybrano pliku"
                ></b-form-file>
              </b-form-group>
            </td>
          </tr>
        </table>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="w-100">
          <p class="float-left">
            <b-button
              size="sm"
              variant="danger"
              @click="handleModalSoftDelete('order-edit-modal-' + order.id)"
            >
              Usuń
            </b-button>
          </p>
          <b-button
            class="float-right mx-2"
            size="sm"
            variant="primary"
            @click="ok()"
          >
            Edytuj
          </b-button>
          <b-button
            class="float-right mx-2"
            size="sm"
            variant="outline-secondary"
            @click="cancel()"
          >
            Anuluj
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ORDER_EDIT,
  ORDER_LIST,
  ORDER_SOFT_DELETE,
} from "@/core/services/store/order.module";
import _ from "lodash";
import Swal from "sweetalert2";
import moment from "moment";
import currency from "currency.js";
import { encode } from "@/core/plugins/base64";
import { baseURL } from "@/core/services/api.service";

export default {
  name: "order-edit-modal",
  data() {
    return {
      productColours: {
        "5000kg": "#64A6BD",
        "500kg": "#90A8C3",
        "20000kg": "#ADA7C9",
        "10000kg": "#D7B9D5",
        "25000kg": "#F4CAE0",
        "1000kg": "#BDD5EA",
        "5000l": "#F7F7FF",
        "500l": "#BAF2E9",
        "20000l": "#BAD7F2",
        "10000l": "#F2E2BA",
        "25000l": "#BDBEA9",
        "1000l": "#F2CDAE",
      },
      form: {
        clientId: null,
        orderId: null,
        orderDate: "",
        itemsInOrder: [],
        note: null,
        status: "new", //completed
        paid: false,
        paymentMethod: "transfer", //cash / cod
        daysForPayment: 14,
        setDiscountValue: 0,
        shippingCost: 0,
        shippingPremiumCost: 0,
        shippingSaturdayCost: 0,
        priceTotal: 0,
        boxesSum: [],
        invoiceAddressSelect: "address0",
        shippingAddressSelect: "address0",
        invoiceAddress: {
          name: "",
          city: "",
          postalCode: "",
          street: "",
          houseNumber: "",
        },
        shippingAddress: {
          name: "",
          city: "",
          postalCode: "",
          street: "",
          houseNumber: "",
        },
        file: {
          encodedFile: "",
          extension: "",
          name: "",
        },
        filePath: "",
      },
      showAllProducts: true,
      clientTypeCode: "go",
      paymentMethodsList: [
        { value: "transfer", text: "Przelew" },
        { value: "cash", text: "Gotówka" },
        { value: "cod", text: "Pobranie" },
        { value: "fast_transfer", text: "Szybki przelew" },
        { value: "terminal", text: "Terminal" },
        { value: "blik", text: "BLIK" },
      ],
      availableAddressList: [],
    };
  },
  props: ["order"],
  methods: {
    getProductColor(product) {
      let key = product.capacity + product.unit.trim().toLowerCase();

      return this.productColours[key];
    },
    editOrder() {
      Swal.fire({
        title: "Na pewno edytować?",
        text: "Na pewno chcesz to zrobić?",
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleSubmit();
        }
      });
    },
    softDeleteOrder() {
      Swal.fire({
        title: "Na pewno usunąć?",
        text: "Na pewno chcesz to zrobić?",
        icon: "warning",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleSoftDelete();
        }
      });
    },
    handleChangeShippingPremiumCost(value) {
      this.form.shippingPremiumCost = currency(value).value;
    },
    handleChangeShippingSaturdayCost(value) {
      this.form.shippingSaturdayCost = currency(value).value;
    },
    handleChangeShippingCost(value) {
      this.form.shippingCost = currency(value).value;
    },
    handleChangeNote(value) {
      this.form.note = value;
    },
    handleSoftDelete() {
      this.$store
        .dispatch(ORDER_SOFT_DELETE, { id: this.order.id })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch(ORDER_LIST);
          }, 2000);
        });
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("order-edit-modal-" + this.order.id);
        Swal.fire("Gotowe!", "Usuwanie udane.", "success");
      });
    },
    handleChangeOrderDate: _.debounce(function ($event) {
      let newValue = null;
      if ($event !== "") {
        newValue = new moment($event);
      }
      let newToCompare = null;
      if (newValue !== null) {
        newToCompare = moment(newValue).format("YYYY-MM-DD");
      }
      this.form.orderDate = newToCompare;
    }, 300),
    resetModal() {},
    handleSubmit() {
      //filter only items that were added to order (quantity)
      this.form.itemsInOrder = _.filter(this.form.itemsInOrder, function (o) {
        return o.quantity != 0;
      });
      let form = Object.assign({}, this.form);
      form.shippingCost = currency(this.form.shippingCost).intValue;
      form.shippingPremiumCost = currency(
        this.form.shippingPremiumCost
      ).intValue;
      form.shippingSaturdayCost = currency(
        this.form.shippingSaturdayCost
      ).intValue;
      form.priceTotal = this.form.priceTotal.intValue;

      this.$store.dispatch(ORDER_EDIT, form).then(() => {
        setTimeout(() => {
          this.$store.dispatch(ORDER_LIST);
        }, 2000);
      });
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("order-edit-modal-" + this.order.id);
        Swal.fire("Gotowe!", "Edycja udana.", "success");
      });
    },
    handleModalOk(event) {
      event.preventDefault();
      this.editOrder();
    },
    handleModalSoftDelete() {
      this.softDeleteOrder();
    },
    handleChangeFile(event) {
      const file = event.target.files[0];
      encode(file).then((data) => {
        this.form.file = {
          name: file.name,
          extension:
            file.name.substring(
              file.name.lastIndexOf(".") + 1,
              file.name.length
            ) || file.name,
          encodedFile: data.split(",")[1],
        };
      });
    },
    transformPrice(value) {
      return currency(value, { fromCents: true });
    },
    handleChangeItemQuantity(value, id) {
      let newOrder = this.form.itemsInOrder.slice();
      const index = newOrder.findIndex((o) => o.id === id);
      if (index !== -1) {
        let item = newOrder[index];
        item.total = Math.round(item.priceBrutto * Number(value) * 100) / 100;
        this.form.itemsInOrder = newOrder;
      }
      this.handleChangeItemDiscount(value, id);
      this.recalculateOrderBoxesSum();
    },
    handleChangeItemDiscount(value, id) {
      let newOrder = this.form.itemsInOrder.slice();
      const index = newOrder.findIndex((o) => o.id === id);
      if (index !== -1) {
        let item = newOrder[index];
        item.totalAfterDiscount = this.calculateDiscountedPrice(
          item.total,
          item.discount
        );
        this.form.itemsInOrder = newOrder;
      }
    },
    calculateDiscountedPrice(price, discount) {
      return Math.round(price - price * Number(discount / 100));
    },
    initModal() {
      // console.log("initModal");
      this.initAddresses();
      let clientDiscountValue =
        this.order.client.sales_discountValue === ""
          ? 0
          : _.parseInt(this.order.client.sales_discountValue);
      this.form.itemsInOrder = this.itemsInOrderSorted;
      this.form.itemsInOrder.forEach((item) => {
        item.quantity = 0;
        item.total = item.priceBrutto * item.quantity;
        item.discount = item.isSlim ? 0 : clientDiscountValue;
        item.totalAfterDiscount = this.calculateDiscountedPrice(
          item.total,
          item.discount
        );
      });
      this.order.salesOrderItems.forEach((soi) => {
        let items = this.form.itemsInOrder.slice();
        const index = items.findIndex((o) => o.id === soi.productId);
        if (index !== -1) {
          let item = items[index];
          item.discount = soi.discount;
          item.priceBrutto = soi.priceBrutto;
          item.quantity = soi.quantity;
          item.total = soi.total;
          item.totalAfterDiscount = soi.totalAfterDiscount;
          this.form.itemsInOrder = items;
        }
      });
      this.form.paymentMethod = this.order.paymentMethod;
      this.form.daysForPayment = this.order.daysForPayment;
      this.form.shippingCost = currency(this.order.shippingCost, {
        fromCents: true,
      }).value;
      this.form.shippingPremiumCost = currency(this.order.shippingPremiumCost, {
        fromCents: true,
      }).value;
      this.form.shippingSaturdayCost = currency(
        this.order.shippingSaturdayCost,
        { fromCents: true }
      ).value;
      this.form.status = this.order.status;
      this.form.paid = this.order.paid;
      this.form.note = this.order.note;
      this.form.filePath = this.order.filePath;
      let dateMoment = new moment(this.order.orderDate, "DD.MM.YYYY");
      this.form.orderDate = dateMoment.format("YYYY-MM-DD");
      if (_.includes(this.order.client.types, "golebie")) {
        if (this.order.client.types.length === 1) {
          //only golebie
          //default showAllProducts disabled - enabled shows others
          this.showAllProducts = false;
          this.clientTypeCode = "g";
        } else {
          //golebie and others
          //default showAllProducts enabled - enabled shows golebie
          this.showAllProducts = true;
          this.clientTypeCode = "go";
        }
      } else {
        //others
        //default showAllProducts disabled - enabled shows golebie
        this.showAllProducts = false;
        this.clientTypeCode = "o";
      }
    },
    fillAddressValuesBasedOnOrder(value, addressType) {
      this.form[addressType + "Select"] = value;
      this.form[addressType].name = this.order[addressType].name;
      this.form[addressType].city = this.order[addressType].city;
      this.form[addressType].postalCode = this.order[addressType].postalCode;
      this.form[addressType].street = this.order[addressType].street;
      this.form[addressType].houseNumber = this.order[addressType].houseNumber;
      this.form[addressType].apartmentNumber =
        this.order[addressType].apartmentNumber;
    },
    fillAddressValuesBasedOnSelect(value, addressType) {
      if (value === "address0") {
        this.form[addressType + "Select"] = "address0";
        this.form[addressType].name = this.order.client.name;
        this.form[addressType].city = this.order.client.city;
        this.form[addressType].postalCode = this.order.client.postalCode;
        this.form[addressType].street = this.order.client.street;
        this.form[addressType].houseNumber = this.order.client.houseNumber;
        this.form[addressType].apartmentNumber =
          this.order.client.apartmentNumber;
      }
      if (value === "address1") {
        this.form[addressType + "Select"] = "address1";
        this.form[addressType].name = this.order.client.deliveryName;
        this.form[addressType].city = this.order.client.deliveryCity;
        this.form[addressType].postalCode =
          this.order.client.deliveryPostalCode;
        this.form[addressType].street = this.order.client.deliveryStreet;
        this.form[addressType].houseNumber =
          this.order.client.deliveryHouseNumber;
        this.form[addressType].apartmentNumber =
          this.order.client.deliveryApartmentNumber;
      }
      if (value === "address2") {
        this.form[addressType + "Select"] = "address2";
        this.form[addressType].name = this.order.client.deliveryName2;
        this.form[addressType].city = this.order.client.deliveryCity2;
        this.form[addressType].postalCode =
          this.order.client.deliveryPostalCode2;
        this.form[addressType].street = this.order.client.deliveryStreet2;
        this.form[addressType].houseNumber =
          this.order.client.deliveryHouseNumber2;
        this.form[addressType].apartmentNumber =
          this.order.client.deliveryApartmentNumber2;
      }
    },
    prepareAvailableAddressList() {
      this.availableAddressList = [];
      this.availableAddressList.push({ value: "address0", text: "Adres 0" });

      if (this.order.client.isDeliveryAddressEnabled) {
        this.availableAddressList.push({ value: "address1", text: "Adres 1" });
      }
      if (this.order.client.isDeliveryAddress2Enabled) {
        this.availableAddressList.push({ value: "address2", text: "Adres 2" });
      }
      return this.availableAddressList;
    },
    recalculatePriceTotal() {
      this.form.priceTotal = currency(this.form.shippingPremiumCost)
        .add(currency(this.form.shippingSaturdayCost))
        .add(currency(this.form.shippingCost))
        .add(this.getOrderTotalAfterDiscount);
    },
    setDiscount() {
      let newOrder = this.form.itemsInOrder.slice();
      newOrder.forEach((item) => {
        if (!item.isSlim) {
          item.discount = Number(this.form.setDiscountValue);
          item.totalAfterDiscount = this.calculateDiscountedPrice(
            item.total,
            item.discount
          );
        }
      });
      this.form.itemsInOrder = newOrder;
      this.recalculatePriceTotal();
    },
    recalculateOrderBoxesSum() {
      let boxes = [];
      this.form.itemsInOrder.forEach((item) => {
        let boxType = item.capacity / 1000 + item.unit;
        const index = boxes.findIndex((o) => o.boxType === boxType);
        if (index !== -1) {
          let boxItem = boxes[index];
          boxItem.quantity += Number(item.quantity);
        } else {
          boxes.push({
            boxType: boxType,
            quantity: Number(item.quantity),
          });
        }
      });
      this.form.boxesSum = boxes;
    },
    initAddresses() {
      // console.log("initAddresses");

      this.prepareAvailableAddressList();
      this.fillAddressValuesBasedOnOrder(
        this.order.invoiceAddressSelect,
        "invoiceAddress"
      );
      this.fillAddressValuesBasedOnOrder(
        this.order.shippingAddressSelect,
        "shippingAddress"
      );
    },
  },
  mounted() {
    // if (this.products.length === 0) {
    //   this.$store.dispatch(PRODUCT_LIST);
    // }

    this.initAddresses();

    this.form.orderId = this.order.id;
    this.form.clientId = this.order.client.id;
  },
  watch: {
    "form.invoiceAddressSelect": function (newValue) {
      this.fillAddressValuesBasedOnSelect(newValue, "invoiceAddress");
    },
    "form.shippingAddressSelect": function (newValue) {
      this.fillAddressValuesBasedOnSelect(newValue, "shippingAddress");
    },
    "form.shippingCost": function () {
      this.recalculatePriceTotal();
    },
    "form.shippingPremiumCost": function () {
      this.recalculatePriceTotal();
    },
    "form.shippingSaturdayCost": function () {
      this.recalculatePriceTotal();
    },
    getOrderTotalAfterDiscount: function () {
      this.recalculatePriceTotal();
    },
  },
  computed: {
    ...mapGetters(["products", "productsAll"]),
    getOrderDate() {
      let dateMoment = new moment(this.order.orderDate, "DD.MM.YYYY");
      let date = new Date(dateMoment.format());
      return date.toLocaleDateString("pl-PL");
    },
    getOrderStandardQuantitySum() {
      let countSum = 0;
      this.form.itemsInOrder.forEach((item) => {
        if (!item.isSlim) {
          countSum += Number(item.quantity);
        }
      });
      return countSum;
    },
    getOrderSlimQuantitySum() {
      let countSum = 0;
      this.form.itemsInOrder.forEach((item) => {
        if (item.isSlim) {
          countSum += Number(item.quantity);
        }
      });
      return countSum;
    },
    getOrderQuantitySum() {
      let countSum = 0;
      this.form.itemsInOrder.forEach((item) => {
        countSum += Number(item.quantity);
      });
      return countSum;
    },
    getOrderStandardTotal() {
      let total = 0;
      this.form.itemsInOrder.forEach((item) => {
        if (!item.isSlim) {
          total += item.total;
        }
      });
      // return Math.round(total * 100) / 100;
      return currency(total, { fromCents: true });
    },
    getOrderSlimTotal() {
      let total = 0;
      this.form.itemsInOrder.forEach((item) => {
        if (item.isSlim) {
          total += item.total;
        }
      });
      // return Math.round(total * 100) / 100;
      return currency(total, { fromCents: true });
    },
    getOrderTotal() {
      let total = 0;
      this.form.itemsInOrder.forEach((item) => {
        total += item.total;
      });
      // return Math.round(total * 100) / 100;
      return currency(total, { fromCents: true });
    },
    getOrderStandardTotalAfterDiscount() {
      let totalAfterDiscount = 0;
      this.form.itemsInOrder.forEach((item) => {
        if (!item.isSlim) {
          totalAfterDiscount += item.totalAfterDiscount;
        }
      });
      // return Math.round(totalAfterDiscount * 100) / 100;
      return currency(totalAfterDiscount, { fromCents: true });
    },
    getOrderSlimTotalAfterDiscount() {
      let totalAfterDiscount = 0;
      this.form.itemsInOrder.forEach((item) => {
        if (item.isSlim) {
          totalAfterDiscount += item.totalAfterDiscount;
        }
      });
      // return Math.round(totalAfterDiscount * 100) / 100;
      return currency(totalAfterDiscount, { fromCents: true });
    },
    getOrderTotalAfterDiscount() {
      let totalAfterDiscount = 0;
      this.form.itemsInOrder.forEach((item) => {
        totalAfterDiscount += item.totalAfterDiscount;
      });
      // return Math.round(totalAfterDiscount * 100) / 100;
      return currency(totalAfterDiscount, { fromCents: true });
    },
    getOrderStandardTotalDiscountValue() {
      return this.getOrderStandardTotal.subtract(
        this.getOrderStandardTotalAfterDiscount
      );
    },
    getOrderSlimTotalDiscountValue() {
      return this.getOrderSlimTotal.subtract(
        this.getOrderSlimTotalAfterDiscount
      );
    },
    getOrderTotalDiscountValue() {
      return this.getOrderTotal.subtract(this.getOrderTotalAfterDiscount);
    },
    getBoxesCountAsText() {
      let str = "";
      this.form.boxesSum.forEach((boxItem) => {
        if (boxItem.quantity > 0) {
          str += "[" + boxItem.quantity + " x " + boxItem.boxType + "] ";
        }
      });
      return str;
    },
    itemsInOrderSorted() {
      // console.log("itemsInOrderSorted");
      let activeProducts = _.filter(this.productsAll, (p) => {
        if (p.deleted === 0) {
          return true;
        }
        const index = _.findIndex(this.order.salesOrderItems, (soi) => {
          return soi.productId == p.id;
        });
        return index !== -1;
      });

      let golebioweProducts = _.filter(activeProducts, function (o) {
        return o.category === "golebiowa" && !o.isSlim;
      });
      golebioweProducts = _.sortBy(golebioweProducts, ["capacity", "name"]);

      let podstawoweProducts = _.filter(activeProducts, function (o) {
        return o.category === "podstawowa" && !o.isSlim;
      });
      podstawoweProducts = _.sortBy(podstawoweProducts, ["capacity", "name"]);

      let slimProducts = _.filter(activeProducts, function (o) {
        return o.isSlim;
      });
      slimProducts = _.sortBy(slimProducts, ["capacity", "name"]);

      return _.concat(golebioweProducts, podstawoweProducts, slimProducts);
    },
    getBaseURL() {
      return baseURL;
    },
  },
};
</script>
