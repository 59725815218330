<template>
  <div>
    <!-- The modal -->
    <b-modal
      :id="'upload-file-modal-' + order.id"
      ref="modal"
      size="lg"
      title="Wgraj plik"
      @show="initModal"
      @hide="resetModal"
      @cancel="resetModal"
      @ok="handleModalOk"
      ok-title="Edytuj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <div class="table-responsive">
        <table class="table table-vertical-center table-sm table-striped">
          <tr class="d-flex font-size-sm">
            <td class="col-12">
              <div class="mb-5">
                Obecne wgrany plik:
                <a
                  v-if="form.filePath !== ''"
                  :href="getBaseURL + form.filePath"
                  target="_blank"
                >
                  POBIERZ
                </a>
              </div>
            </td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12">
              <b-form-group label-for="file">
                <b-form-file
                  id="file"
                  @change="handleChangeFile($event)"
                  accept=".pdf"
                  browse-text="Przeglądaj"
                  placeholder="Nie wybrano pliku"
                ></b-form-file>
              </b-form-group>
            </td>
          </tr>
        </table>
      </div>

      <template #modal-footer="{ ok, cancel, hide }">
        <div class="w-100">
          <b-button
            class="float-right mx-2"
            size="sm"
            variant="primary"
            @click="ok()"
          >
            Edytuj
          </b-button>
          <b-button
            class="float-right mx-2"
            size="sm"
            variant="outline-secondary"
            @click="cancel()"
          >
            Anuluj
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ORDER_EDIT_FILE,
} from "@/core/services/store/order.module";
import Swal from "sweetalert2";
import { encode } from "@/core/plugins/base64";
import { baseURL } from "@/core/services/api.service";

export default {
  name: "upload-file-modal",
  data() {
    return {
      form: {
        clientId: null,
        orderId: null,
        file: {
          encodedFile: "",
          extension: "",
          name: ""
        },
        filePath: ""
      }
    };
  },
  props: ["order"],
  methods: {
    editOrder() {
      Swal.fire({
        title: "Na pewno?",
        text: "Wgranie pliku nadpisze poprzedni!",
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.isConfirmed) {
          this.handleSubmit();
        }
      });
    },
    resetModal() {
      //should this reset form fields?
    },
    handleSubmit() {
      let payload = {
        orderId: this.form.orderId,
        file: this.form.file
      };
      this.$store.dispatch(ORDER_EDIT_FILE, payload).then(() => {
        setTimeout(() => {
          this.$bvModal.hide("upload-file-modal-" + this.order.id);
          Swal.fire("Gotowe!", "Edycja udana.", "success");
        }, 2000);
      });
      // this.$nextTick(() => {
      //   this.$bvModal.hide("upload-file-modal-" + this.order.id);
      //   Swal.fire("Gotowe!", "Edycja udana.", "success");
      // });
    },
    handleModalOk(event) {
      event.preventDefault();
      this.editOrder();
    },
    handleChangeFile(event) {
      const file = event.target.files[0];
      encode(file).then(data => {
        this.form.file = {
          name: file.name,
          extension:
            file.name.substring(
              file.name.lastIndexOf(".") + 1,
              file.name.length
            ) || file.name,
          encodedFile: data.split(",")[1]
        };
      });
    },
    initModal() {
      this.form.filePath = this.order.filePath;
    }
  },
  mounted() {
    this.form.orderId = this.order.id;
    this.form.clientId = this.order.client.id;
  },
  computed: {
    ...mapGetters(["products"]),
    getBaseURL() {
      return baseURL;
    }
  }
};
</script>
