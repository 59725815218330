<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <b-button v-b-toggle.collapse-filter variant="primary" class="mt-3"
          >Filtry</b-button
        >
        <b-button variant="primary" class="mt-3" @click="showOverduePayments">Pokaż zaległe płatności</b-button>
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <b-collapse visible id="collapse-filter">
        <div class="row mb-3">
          <div class="col-lg-2">
            <b-form-group label="Nazwa" label-for="name">
              <b-form-input
                id="name"
                placeholder="Podaj nazwę"
                v-model="nameFilter"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <b-form-group
              label="Osoba do kontaktu"
              label-for="contactPersonFullNameFilter"
            >
              <b-form-input
                id="contactPersonFullNameFilter"
                placeholder="Imię Nazwisko"
                v-model="contactPersonFullNameFilter"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <b-form-group label="Status" label-for="soStatusFilter">
              <b-form-select
                id="soStatusFilter"
                v-model="soStatusFilter"
                multiple
                :options="soStatusList"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <ChooseAttendants v-model="attendantFilter" />
          </div>
          <div class="col-lg-4">
            <ChooseProducts v-model="productsFilter" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-2">
            <b-form-group
              label="Data zamówienia Od"
              label-for="orderDateFilterFrom"
            >
              <b-form-datepicker
                reset-button
                type="date"
                placeholder="dd.mm.rrrr"
                size="md"
                :start-weekday="1"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                id="orderDateFilterFrom"
                v-model="orderDateFilterFrom"
              >
              </b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <b-form-group
              label="Data zamówienia Do"
              label-for="orderDateFilterTo"
            >
              <b-form-datepicker
                reset-button
                type="date"
                placeholder="dd.mm.rrrr"
                size="md"
                :start-weekday="1"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                id="orderDateFilterTo"
                v-model="orderDateFilterTo"
              >
              </b-form-datepicker>
            </b-form-group>
          </div>
          <div class="col-lg-1">
            <b-form-group label="Kwota Od" label-for="priceTotalFromFilter">
              <b-form-input
                type="number"
                min="0"
                step="1"
                v-model="priceTotalFromFilter"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-1">
            <b-form-group label="Kwota Do" label-for="priceTotalToFilter">
              <b-form-input
                type="number"
                min="0"
                step="1"
                v-model="priceTotalToFilter"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-lg-2">
            <b-form-group label="Nr faktury" label-for="invoiceNumber">
              <b-form-input
                id="invoiceNumber"
                placeholder="Nr faktury"
                v-model="invoiceNumberFilter"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <b-form-group label="Sposób płatności" label-for="paidFilter">
              <b-form-select
                id="paidFilter"
                v-model="paymentMethodsFilter"
                multiple
                :options="paymentMethodsList"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-lg-2">
            <b-form-group label="Płatność" label-for="paidFilter">
              <b-form-select
                id="paidFilter"
                v-model="paidFilter"
                multiple
                :options="paidList"
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
      </b-collapse>

      <template v-for="(client, clientIndex) in clients">
        <div :key="'client-edit-index-' + clientIndex">
          <ClientEditModal :client="client" />
        </div>
      </template>

      <!--begin::Table-->
      <div class="table-responsive">
        <v-data-table
          dense
          v-model="selected"
          :headers="computedHeaders"
          :items="dataToShow"
          class="table custom-table table-head-custom table-vertical-center table-head-bg table-borderless"
          item-key="id"
          mobile-breakpoint="0"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageText: 'Wyników na stronę',
            pageText: '{0}-{1} na {2}',
            itemsPerPageOptions: [10, 30, 50, 100],
          }"
          :items-per-page="10"
        >
          <template v-slot:header.modals="{}">
            <div style="width: 0; padding: 0 !important"></div>
          </template>
          <template v-slot:header.actions="{ header }">
            <div class="text-right">
              {{ header.text }}
            </div>
          </template>

          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="(so, index) in items.filter( el => showOverdue ? isPaymentOverdue(el) : true )">
                <tr class="random" :key="'so-' + so.id">
                  <td style="width: 0; padding: 0 !important">
                    <OrderEditModal :order="so" />
                    <UploadFileModal :order="so" />
                    <MailModal :order="so" />
                  </td>
                  <td style="min-width: 80px">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ so.orderDate }}
                      <template v-if="isPaymentOverdue(so)">
                        <br><span style="color: red">Zaległa płatność</span>
                      </template>
                    </a>
                  </td>
                  <td style="min-width: 200px">
                    <a
                      @click="edit(so.clientId)"
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      <!--                   TODO:   GETTING ERRORS HERE-->
                      {{ getClient(so.clientId).name }}
                    </a>
                  </td>
                  <td style="min-width: 80px">
                    <span class="text-dark-75 d-block font-size-xs">
                      {{ getClient(so.clientId).contactPersonFullName }}
                    </span>
                  </td>
                  <td style="min-width: 120px">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      <b-form-select
                        @input="handleChangeStatus($event, so)"
                        size="sm"
                        id="status"
                        :value="so.status"
                        :options="soStatusList"
                        :class="{
                          'bg-secondary': statusIsCurrentlyEditedAndNotSaved(
                            so.id
                          ),
                        }"
                      ></b-form-select>
                    </a>
                  </td>
                  <td style="min-width: 120px">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      <b-form-input
                        @input="handleChangeInvoiceNumber($event, so)"
                        :value="so.invoiceNumber"
                        size="sm"
                        :class="{
                          'bg-secondary':
                            invoiceNumberIsCurrentlyEditedAndNotSaved(so.id),
                        }"
                      >
                      </b-form-input>
                    </a>
                  </td>
                  <td style="min-width: 120px">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      <b-form-select
                        @input="handleChangePaid($event, so)"
                        size="sm"
                        id="paid"
                        :value="so.paid"
                        :options="paidList"
                        :class="{
                          'bg-secondary': paidIsCurrentlyEditedAndNotSaved(
                            so.id
                          ),
                        }"
                      ></b-form-select>
                    </a>
                  </td>
                  <td style="min-width: 80px">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ transformPrice(so.priceTotal) }} zł
                    </a>
                  </td>
                  <td style="min-width: 80px">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{ so.paymentDate }}
                    </a>
                  </td>
                  <td style="min-width: 40px">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                    >
                      {{
                        getTranslatedAttendant(so.client.sales_attendant)
                      }}
                    </a>
                  </td>
                  <td style="min-width: 80px">
                    <a
                      class="text-dark-75 text-hover-primary mb-1 font-size-xs"
                      :href="'mailto:' + getClient(so.clientId).email"
                    >
                      {{ getEmails( getClient(so.clientId) ) }}
                    </a>
                  </td>

                  <td class="text-right" style="min-width: 200px">
                    <a
                      class="btn btn-light btn-xs mr-2"
                      @click="saveOrder(so)"
                      :class="{
                        'bg-secondary':
                          statusIsCurrentlyEditedAndNotSaved(so.id) ||
                          invoiceNumberIsCurrentlyEditedAndNotSaved(so.id) ||
                          paidIsCurrentlyEditedAndNotSaved(so.id),
                      }"
                    >
                      <i class="fa fa-check p-0 text-primary"></i>
                    </a>

                    <b-button-group>
                      <b-dropdown
                        no-caret
                        variant="outline"
                        class="bg-light mr-2"
                      >
                        <template #button-content>
                          <span class="m-0">
                            <i
                              class="fas fa-file-invoice-dollar p-0 text-primary"
                            ></i>
                          </span>
                        </template>
                        <b-dropdown-item>
                          <a
                            class=""
                            @click="printOrder(so)"
                            :class="{
                              disabled: isPrinting,
                              'text-decoration-line-through': isPrinting,
                            }"
                          >
                            <i class="fa fa-file-pdf p-0 text-primary"></i
                            >&nbsp;&nbsp;Pobierz PDF
                          </a>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <a class="" @click="uploadFile(so)">
                            <i class="fa fa-upload p-0 text-primary"></i
                            >&nbsp;&nbsp;Wgraj Fakturę
                          </a>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="so.filePath !== ''">
                          <a
                            class=""
                            @click="sentOrderEmail(so)"
                            :class="{
                              disabled: so.filePath === '',
                              'text-decoration-line-through':
                                so.filePath === '',
                            }"
                          >
                            <i class="fa fa-envelope p-0 text-primary"></i
                            >&nbsp;&nbsp;Wyślij e-mail
                          </a>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="isPaymentOverdue(so)">
                          <a @click="getDemandOfPayment(so)">
                            <i class="fa fa-file-pdf p-0 text-primary"></i>
                            Pobierz wezwanie do zapłaty
                          </a>
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-button-group>

                    <a class="btn btn-light btn-xs mr-2" @click="editOrder(so)">
                      <i class="fa fa-edit p-0 text-primary"></i>
                    </a>

                    <b-button
                      type="button"
                      @click="handleCollapseSOI(so.id)"
                      class="btn btn-light btn-xs"
                    >
                      <template v-if="isSOIListVisible(so.id)">
                        <i class="fa fa-angle-up p-0 text-primary"></i>
                      </template>
                      <template v-else>
                        <i class="fa fa-angle-down p-0 text-primary"></i>
                      </template>
                    </b-button>
                  </td>
                </tr>
                <tr :key="'rand-' + index + 2000">
                  <td class="bg-white" colspan="12">
                    <b-collapse :visible="isSOIListVisible(so.id)" class="mt-2">
                      <table
                        class="table table-vertical-center table-noradius table-sm"
                      >
                        <thead>
                          <tr class="d-flex">
                            <th class="col-2">Nazwa</th>
                            <th class="col-1">Pojemność</th>
                            <th class="col-1">Ilosc</th>
                            <th class="col-1">Wartosc</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            v-for="soi in getSalesOrderItemsToShow(so.id)"
                          >
                            <tr
                              :key="'soi-' + soi.id"
                              class="d-flex cursor-pointer text-hover-primary bg-secondary"
                            >
                              <td class="col-2">
                                {{ soi.productName }}
                              </td>
                              <td class="col-1">
                                {{ soi.productCapacity / 1000 }}
                                {{ soi.productUnit }}
                              </td>
                              <td class="col-1">
                                {{ soi.quantity }}
                              </td>
                              <td class="col-1">
                                {{ transformPrice(soi.totalAfterDiscount) }} zł
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </b-collapse>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
          <template v-slot:item.lastOrderDate="{ item }">
            <span>{{ new Date(item.lastOrderDate).toLocaleString() }}</span>
          </template>
        </v-data-table>
      </div>

      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { CLIENT_LIST } from "@/core/services/store/client.module";
import { ORDER_LIST } from "@/core/services/store/order.module";
import { USER_LIST } from "@/core/services/store/user.module";
import {
  ORDER_EDIT_FIELD,
  ORDER_PRINT,
} from "@/core/services/store/order.module";
import OrderEditModal from "@/view/content/modal/OrderEditModal.vue";
import UploadFileModal from "@/view/content/modal/UploadFileModal.vue";
import MailModal from "@/view/content/modal/MailModal.vue";
import Ripple from "vuetify/lib/directives/ripple";
import Vuetify from "vuetify";
import ClientEditModal from "@/view/content/modal/ClientEditModal";
import currency from "currency.js";
import moment from "moment";
import ChooseAttendants from "@/view/content/form/ChooseAttendants";
import ChooseProducts from "@/view/content/form/ChooseProducts";
import { VARIABLE_LIST } from "@/core/services/store/variable.module";
import {
  PRODUCT_LIST,
  PRODUCT_ALL_LIST,
} from "@/core/services/store/product.module";
import { FERM_LIST } from "@/core/services/store/ferm.module";
export default {
  name: "orders-table",
  components: {
    ClientEditModal,
    OrderEditModal,
    UploadFileModal,
    MailModal,
    ChooseAttendants,
    ChooseProducts,
  },
  directives: {
    Ripple,
  },
  vuetify: new Vuetify(),
  data() {
    return {
      editingStatus: [],
      showOverdue: false,
      editingPaid: [],
      editingInvoiceNumber: [],
      salesOrderItemsToShow: [],
      ordersForCollapse: [],
      collapseSOI: [],
      attendantFilter: [],
      voivodeshipList: [
        { value: "-select-", text: "Wybierz" },
        { value: "dolnośląskie", text: "dolnośląskie" },
        { value: "kujawsko-pomorskie", text: "kujawsko-pomorskie" },
        { value: "lubelskie", text: "lubelskie" },
        { value: "lubuskie", text: "lubuskie" },
        { value: "łódzkie", text: "łódzkie" },
        { value: "mazowieckie", text: "mazowieckie" },
        { value: "małopolskie", text: "małopolskie" },
        { value: "opolskie", text: "opolskie" },
        { value: "podkarpackie", text: "podkarpackie" },
        { value: "podlaskie", text: "podlaskie" },
        { value: "pomorskie", text: "pomorskie" },
        { value: "śląskie", text: "śląskie" },
        { value: "świętokrzyskie", text: "świętokrzyskie" },
        { value: "warmińsko-mazurskie", text: "warmińsko-mazurskie" },
        { value: "wielkopolskie", text: "wielkopolskie" },
        { value: "zachodniopomorskie", text: "zachodniopomorskie" },
      ],
      voivodeshipFilter: "-select-",
      soStatusFilter: [],
      soStatusList: [
        { value: "new", text: "Nowe" },
        { value: "completed", text: "Zrealizowane" },
      ],
      nameFilter: "",
      contactPersonFullNameFilter: "",
      priceTotalFromFilter: "",
      priceTotalToFilter: "",
      invoiceNumberFilter: "",
      paidFilter: [],
      paidList: [
        { value: true, text: "Zapłacone" },
        { value: false, text: "Niezapłacone" }
      ],
      paymentMethodsFilter: [],
      paymentMethodsList: [
        { value: "transfer", text: "Przelew" },
        { value: "cash", text: "Gotówka" },
        { value: "cod", text: "Pobranie" },
        { value: "fast_transfer", text: "Szybki przelew" },
        { value: "terminal", text: "Terminal" },
        { value: "blik", text: "BLIK" },
      ],
      typeFilter: "",
      typeList: [
        { value: "", text: "Wybierz" },
        { value: "gesi", text: "Gęsi" },
        { value: "kaczki", text: "Kaczki" },
        { value: "indyki", text: "Indyki" },
        { value: "nioski reprodukcyjne", text: "Nioski reprodukcyjne" },
        { value: "nioski towarowe", text: "Nioski towarowe" },
        { value: "brojlery", text: "Brojlery" },
        { value: "golebie", text: "Gołębie" },
        { value: "norki", text: "Norki" },
        { value: "odchowka", text: "Odchówka" },
        { value: "kroliki", text: "Króliki" },
        { value: "inne", text: "Inne" },
      ],
      orderDateFilterFrom: "",
      orderDateFilterTo: "",
      sizeFrom: "",
      sizeTo: "",
      productsFilter: [],
      productsList: [],
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "",
          value: "modals",
          sortable: false,
          style: "width: 0; padding: 0 !important;",
        },
        {
          text: "Data zamówienia",
          value: "orderDateObj",
          style: "min-width: 80px;",
          sortable: true,
          filter: (value) => {
            //value.date to data zamowienia
            if (value === null) return false;
            if (!this.orderDateFilterFrom && !this.orderDateFilterTo) {
              return true;
            }
            let jsDate = new Date(value.date);
            let momentDate = new moment(jsDate);
            if (this.orderDateFilterFrom && !this.orderDateFilterTo) {
              return momentDate.isSameOrAfter(
                new moment(this.orderDateFilterFrom)
              );
            }
            if (!this.orderDateFilterFrom && this.orderDateFilterTo) {
              return momentDate.isSameOrBefore(
                new moment(this.orderDateFilterTo)
              );
            }
            return (
              momentDate.isSameOrAfter(new moment(this.orderDateFilterFrom)) &&
              momentDate.isSameOrBefore(new moment(this.orderDateFilterTo))
            );
          },
          sort: (a, b) => {
            if (a === null && b === null) {
              return 0;
            }
            if (a === null && b !== null) {
              return 1;
            }
            if (a !== null && b === null) {
              return -1;
            }
            if (a !== null && b !== null) {
              return new Date(a.date) - new Date(b.date);
            }
            return 0;
          },
        },
        {
          text: "Nazwa firmy lub Imię i Nazwisko klienta",
          value: "clientName",
          filter: (value) => {
            if (!this.nameFilter) return true;
            return value.toLowerCase().includes(this.nameFilter.toLowerCase());
          },
          sortable: true,
          style: "min-width: 200px;",
        },
        {
          text: "Osoba do kontaktu",
          value: "contactPersonFullName",
          filter: (value) => {
            if (!this.contactPersonFullNameFilter) return true;
            return value
              .toLowerCase()
              .includes(this.contactPersonFullNameFilter.toLowerCase());
          },
          style: "min-width: 80px;",
        },
        {
          text: "Status",
          value: "status",
          style: "min-width: 120px;",
          filter: (value) => {
            if (this.soStatusFilter.length === 0) return true;
            if (value === null) return false;
            return _.includes(this.soStatusFilter, value);
          },
        },
        {
          text: "Nr faktury",
          value: "invoiceNumber",
          style: "min-width: 120px;",
          filter: (value) => {
            if (!this.invoiceNumberFilter) return true;
            if (value === null) return false;
            return value
              .toLowerCase()
              .includes(this.invoiceNumberFilter.toLowerCase());
          },
          sortable: true,
        },
        {
          text: "Płatność",
          value: "paid",
          style: "min-width: 120px;",
          filter: (value) => {
            if (this.paidFilter.length === 0) return true;
            if (value === null) return false;
            return _.includes(this.paidFilter, value);
          },
        },
        {
          text: "Suma",
          value: "priceTotal",
          style: "min-width: 80px;",
          sortable: true,
          filter: (value) => {
            if (value === null) return false;
            if (!this.priceTotalFromFilter && !this.priceTotalToFilter) {
              return true;
            }
            if (this.priceTotalFromFilter && !this.priceTotalToFilter) {
              return value >= this.priceTotalFromFilter * 100;
            }
            if (!this.priceTotalFromFilter && this.priceTotalToFilter) {
              return value <= this.priceTotalToFilter * 100;
            }
            return (
              value >= this.priceTotalFromFilter * 100 &&
              value <= this.priceTotalToFilter * 100
            );
          },
        },
        {
          text: "Termin płatności",
          value: "paymentDateObj",
          style: "min-width: 80px;",
          sortable: true,
          sort: (a, b) => {
            if (a === null && b === null) {
              return 0;
            }
            if (a === null && b !== null) {
              return 1;
            }
            if (a !== null && b === null) {
              return -1;
            }
            if (a !== null && b !== null) {
              return new Date(a.date) - new Date(b.date);
            }
            return 0;
          },
        },
        {
          text: "Sposób płatności",
          value: "paymentMethod",
          style: "min-width: 120px;",
          filter: (value) => {
            if (this.paymentMethodsFilter.length === 0) return true;
            if (value === null) return false;
            return _.includes(this.paymentMethodsFilter, value);
          },
          sortable: false,
          align: " d-none",
        },
        {
          text: "Operator",
          value: "client.sales_attendant",
          filter: (value) => {
            if (this.attendantFilter.length === 0) return true;
            if (value === null) return false;
            return _.includes(this.attendantFilter, value);
          },
          style: "min-width: 80px;",
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
          style: "min-width: 80px;",
        },
        // {
        //   text: "Produkty",
        //   value: "salesOrderItems",
        //   style: "min-width: 120px;",
        //   filter: value => {
        //     if (this.productsFilter.length === 0) return true;
        //     if (value === null) return false;
        //     console.log(value);
        //     return true;
        //     // return _.includes(this.productsFilter, value);
        //   },
        //   sortable: false,
        //   align: "dd-none"
        // },

        {
          text: "Produkty",
          value: "salesOrderItems",
          filter: (sois) => {
            //no products selected in filter -> show all
            if (this.productsFilter.length === 0) return true;
            //products of order are null instead of object -> this is error -> hide this order as it is broken
            if (sois === null) return false;
            // below was used when all statuses from filter must match
            // return _.difference(this.statusFilter, statuses).length === 0;
            // below was used when all statuses from filter must match
            // return (
            //     this.statusFilter.length !==
            //     _.difference(this.statusFilter, statuses).length
            // );
            // console.log(this.products);
            // console.log(this.productsFilter);
            let rawSois = _.map(sois, (soi) => {
              return soi.productId;
            });
            // console.log(rawProducts);

            if (_.includes(this.productsFilter, null)) {
              let filters = _.without(this.productsFilter, null);
              if (filters.length === 0) {
                return rawSois.length === 0;
              } else {
                return (
                  filters.length !== _.difference(filters, rawSois).length ||
                  rawSois.length === 0
                );
              }
            }
            //below was used when any status from filter must watch
            return (
              this.productsFilter.length !==
              _.difference(this.productsFilter, rawSois).length
            );
          },
          sortable: false,
          align: " d-none",
        },

        {
          text: "Akcje",
          value: "actions",
          sortable: false,
          style: "min-width: 150px; text-align: right;",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(FERM_LIST);
    this.$store.dispatch(CLIENT_LIST);
    setTimeout(() => {
      this.$store.dispatch(USER_LIST);
      setTimeout(() => {
        this.$store.dispatch(ORDER_LIST);
        if (this.products.length === 0) {
          this.$store.dispatch(PRODUCT_LIST);
          this.$store.dispatch(PRODUCT_ALL_LIST);
        }
      }, 2000);
    }, 2000);
    this.$store.dispatch(VARIABLE_LIST);
  },
  methods: {
    isPaymentOverdue( el ) {
      if ( el.paid == 1)
        return false;

      let orderDate = el.orderDate.split('.');

      let invoiceDate = new Date( orderDate[1] + '/' + orderDate[0] + "/" + orderDate[2] ),
          dueDate = new Date( orderDate[1] + '/' + orderDate[0] + "/" + orderDate[2] ),
          today = new Date();

      dueDate.setDate( invoiceDate.getDate() + el.daysForPayment );
      
      return dueDate.getTime() < today.getTime();
    },
    showOverduePayments() {
      this.showOverdue = !this.showOverdue;
    },
    getEmails(item) {
      return item.sales_email1 + " " + item.sales_email2 + " " + item.sales_email3;
    },
    transformPrice(value) {
      return currency(value, { fromCents: true });
    },
    isSOIListVisible(id) {
      return this.collapseSOI.includes(id);
    },
    getOrderById(id) {
      return _.find(this.orders, (o) => {
        return o.id === id;
      });
    },
    handleCollapseSOI(id) {
      const exists = _.includes(this.collapseSOI, id);
      if (!exists) {
        this.collapseSOI.push(id);
      } else {
        const index = _.findIndex(this.collapseSOI, id);
        let collapseSOI = [...this.collapseSOI];
        collapseSOI.splice(index, 1);
        this.collapseSOI = collapseSOI;
      }
    },
    getTranslatedTypes(types) {
      if (_.isNull(types) || _.size(types) === 0) {
        return "-";
      }
      return types.join(", ");
    },
    edit(itemId) {
      this.$bvModal.show("modal-client-edit-" + itemId);
    },
    printOrder(order) {
      this.$store.dispatch(ORDER_PRINT, {
        id: order.id,
      });
    },
    getDemandOfPayment(order) {
      this.$store.dispatch("orderPrintDemandOfPayment", {
        id: order.id
      });
    },
    sentOrderEmail(order) {
      if (order.filePath !== "") {
        // this.$store.dispatch(ORDER_SENT_EMAIL, {
        //   id: order.id
        // });
        this.$bvModal.show("mail-modal-" + order.id);
      }
    },
    uploadFile(order) {
      this.$bvModal.show("upload-file-modal-" + order.id);
    },
    editOrder(order) {
      this.$bvModal.show("order-edit-modal-" + order.id);
    },
    saveOrder(order) {
      const indexOfStatus = _.findIndex(this.editingStatus, function (o) {
        return o.id === order.id;
      });
      if (indexOfStatus !== -1) {
        const newValue1 = this.editingStatus[indexOfStatus].value;
        this.$store.dispatch(ORDER_EDIT_FIELD, {
          id: order.id,
          name: "status",
          value: newValue1,
        });
        this.editingStatus.splice(indexOfStatus, 1);
      }

      const indexOfInvoiceNumber = _.findIndex(
        this.editingInvoiceNumber,
        function (o) {
          return o.id === order.id;
        }
      );
      if (indexOfInvoiceNumber !== -1) {
        const newValue2 = this.editingInvoiceNumber[indexOfInvoiceNumber].value;
        this.$store.dispatch(ORDER_EDIT_FIELD, {
          id: order.id,
          name: "invoiceNumber",
          value: newValue2,
        });
        this.editingInvoiceNumber.splice(indexOfInvoiceNumber, 1);
      }

      const indexOfPaid = _.findIndex(this.editingPaid, function (o) {
        return o.id === order.id;
      });
      if (indexOfPaid !== -1) {
        const newValue3 = this.editingPaid[indexOfPaid].value;
        this.$store.dispatch(ORDER_EDIT_FIELD, {
          id: order.id,
          name: "paid",
          value: newValue3,
        });
        this.editingPaid.splice(indexOfPaid, 1);
      }
    },
    handleChangeStatus($event, so) {
      let oldValue = so.status;
      let newValue = $event;
      if (newValue === "") {
        newValue = null;
      }
      const index = _.findIndex(this.editingStatus, function (o) {
        return o.id === so.id;
      });
      if (index === -1) {
        this.editingStatus.push({
          id: so.id,
          value: newValue,
        });
      } else {
        if (oldValue === newValue) {
          this.editingStatus.splice(index, 1);
        } else {
          this.editingStatus.splice(index, 1, {
            id: so.id,
            value: newValue,
          });
        }
      }
    },
    handleChangePaid($event, so) {
      let oldValue = so.paid;
      let newValue = $event;
      if (newValue === "") {
        newValue = null;
      }
      const index = _.findIndex(this.editingPaid, function (o) {
        return o.id === so.id;
      });
      if (index === -1) {
        this.editingPaid.push({
          id: so.id,
          value: newValue,
        });
      } else {
        if (oldValue === newValue) {
          this.editingPaid.splice(index, 1);
        } else {
          this.editingPaid.splice(index, 1, {
            id: so.id,
            value: newValue,
          });
        }
      }
    },
    handleChangeInvoiceNumber($event, so) {
      let oldValue = so.invoiceNumber;
      let newValue = $event;
      if (newValue === "") {
        newValue = null;
      }
      const index = _.findIndex(this.editingInvoiceNumber, function (o) {
        return o.id === so.id;
      });
      if (index === -1) {
        this.editingInvoiceNumber.push({
          id: so.id,
          value: newValue,
        });
      } else {
        if (oldValue === newValue) {
          this.editingInvoiceNumber.splice(index, 1);
        } else {
          this.editingInvoiceNumber.splice(index, 1, {
            id: so.id,
            value: newValue,
          });
        }
      }
    },

    getNextContactDate(item) {
      if (item.nextContactDate !== null) {
        if (item.nextContactDate.hasOwnProperty("date")) {
          return new Date(item.nextContactDate.date);
        } else {
          return new Date(item.nextContactDate);
        }
      }
      return null;
    },
    getTranslatedPaymentMethod(pm) {
      if (pm === "transfer") {
        return "Przelew";
      }
      return "Gotówka";
    },
    getSalesOrderItemsToShow(soId) {
      return this.getOrderById(soId).salesOrderItems;
    },
    getClient(clientId) {
      // console.log(clientId);
      // console.log(this.clients);
      let c = _.find(this.clients, (c) => {
        return c.id === clientId;
      });
      // console.log(c);
      return c;
    },
    statusIsCurrentlyEditedAndNotSaved(id) {
      const index = _.findIndex(this.editingStatus, function (o) {
        return o.id === id;
      });
      return index !== -1;
    },
    paidIsCurrentlyEditedAndNotSaved(id) {
      const index = _.findIndex(this.editingPaid, function (o) {
        return o.id === id;
      });
      return index !== -1;
    },
    invoiceNumberIsCurrentlyEditedAndNotSaved(id) {
      const index = _.findIndex(this.editingInvoiceNumber, function (o) {
        return o.id === id;
      });
      return index !== -1;
    },
    getTranslatedAttendant(attId) {
      const a = _.find(this.attendantsList, { value: attId });
      if (!_.isUndefined(a)) return a.text;
      return "";
    },
  },
  computed: {
    ...mapGetters(["clients", "users", "orders", "isPrinting", "products"]),
    dataToShow() {
      return this.orders;
    },
    computedHeaders() {
      return this.headers;
    },
    attendantsList() {
      let result = [
        {
          value: -1,
          text: "Brak",
        },
      ];
      this.users.forEach((user) => {
        result.push({
          value: user.id,
          text: user.firstName + " " + user.lastName,
        });
      });
      return result;
    },
  },
  // watch: {
  //   clients() {
  //     if ( this.clients.length > 0)
  //     {
  //       let prevVal = this.nameFilter;

  //       this.nameFilter = 'a';

  //       this.nameFilter = prevVal;
  //     }
  //   }
  // }
};
</script>
