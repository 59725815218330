<template>
  <div>
    <!-- The modal -->
    <b-modal
      :id="'mail-modal-' + order.id"
      ref="modal"
      size="lg"
      title="Wyślij email"
      @show="initModal"
      @hide="resetModal"
      @cancel="resetModal"
      @ok="handleModalOk"
      ok-title="Wyślij"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <div class="table-responsive">
        <table class="table table-vertical-center table-sm table-striped">
          <tr class="d-flex font-size-sm">
            <td class="col-12">
              <b-form novalidate>
                <b-form-group
                  :label="'Adresat: '"
                  label-for="addressee"
                  invalid-feedback="To pole jest wymagane"
                >
                  <b-form-input
                    v-model="form.addressee"
                    size="lg"
                  >
                  </b-form-input>
                </b-form-group>
              </b-form>
            </td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12">
              <div class="mb-5">
                Załącznik:
                <a
                  v-if="form.filePath !== ''"
                  :href="getBaseURL + filePath"
                  target="_blank"
                >
                  POBIERZ
                </a>
              </div>
            </td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12">
              <b-form novalidate>
                <b-form-group
                  :label="'Temat: '"
                  label-for="title"
                  invalid-feedback="To pole jest wymagane"
                >
                  <b-form-input
                    v-model="form.title"
                    size="lg"
                  >
                  </b-form-input>
                </b-form-group>
              </b-form>
            </td>
          </tr>
          <tr class="d-flex font-size-sm">
            <td class="col-12">
              <b-form novalidate>
                <b-form-group
                  :label="'Treść: '"
                  label-for="content"
                  invalid-feedback="To pole jest wymagane"
                >
                  <b-form-textarea
                    v-model="form.content"
                    :class="{ 'custom-textarea': true }"
                    size="lg"
                    rows="8"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-form>
            </td>
          </tr>
        </table>
      </div>

      <template #modal-footer="{ ok, cancel, hide }">
        <div class="w-100">
          <b-button
            class="float-right mx-2"
            size="sm"
            variant="primary"
            @click="ok()"
          >
            Wyślij
          </b-button>
          <b-button
            class="float-right mx-2"
            size="sm"
            variant="outline-secondary"
            @click="cancel()"
          >
            Anuluj
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { baseURL } from "@/core/services/api.service";
import { ORDER_SEND_MAIL } from "@/core/services/store/order.module";
import _ from "lodash";
// import {required} from "vuelidate/lib/validators";

export default {
  name: "mail-modal",
  data() {
    return {
      form: {
        clientId: null,
        orderId: null,
        title: "",
        content: "",
        addressee: ""
      }
      // validations: {
      //   form: {
      //     content: {
      //       required
      //     }
      //   }
      // }
    };
  },
  props: ["order"],
  methods: {
    sendMail() {
      Swal.fire({
        title: "Na pewno?",
        text: "Czy chcesz wysłać maila?",
        icon: "info",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.isConfirmed) {
          this.handleSubmit();
        }
      });
    },
    resetModal() {
      //should this reset form fields?
    },
    handleSubmit() {
      this.$store.dispatch(ORDER_SEND_MAIL, this.form).then(() => {
        this.$nextTick(() => {
          this.$bvModal.hide("mail-modal-" + this.form.orderId);
        });
      });
      this.$nextTick(() => {
        this.$bvModal.hide("mail-modal-" + this.form.orderId);
      });
      // let payload = {
      //   orderId: this.form.orderId,
      //   file: this.form.file
      // };
      // this.$store.dispatch(ORDER_EDIT_FILE, payload).then(() => {
      //   setTimeout(() => {
      //     this.$bvModal.hide("upload-file-modal-" + this.order.id);
      //     Swal.fire("Gotowe!", "Edycja udana.", "success");
      //   }, 2000);
      // });

      // this.$nextTick(() => {
      //   this.$bvModal.hide("upload-file-modal-" + this.order.id);
      //   Swal.fire("Gotowe!", "Edycja udana.", "success");
      // });
    },
    // validateField(field) {
    //   const { $dirty, $error } = this.$v.form[field];
    //   return $dirty ? !$error : null;
    // },
    handleModalOk(event) {
      event.preventDefault();
      this.sendMail();
    },
    initModal() {
      // this.form.filePath = this.order.filePath;
      this.form.content = this.parseValue(this.getMailTemplateContent.value);
      this.form.title = this.parseValue(this.getMailTemplateTitle.value);
      this.form.addressee = this.order.client.email;
    },
    parseValue(rawPayload) {
      let indexOfVarBegin = -1;
      let indexOfVarEnd = -1;
      do {
        let foundVarValue = "";
        let offsetOfVarBegin = 0;
        let offsetOfVarEnd = 0;
        indexOfVarBegin = rawPayload.indexOf("{#var}", offsetOfVarBegin);
        indexOfVarEnd = rawPayload.indexOf("{/#var}", offsetOfVarEnd);
        if (indexOfVarBegin !== -1 && indexOfVarEnd !== -1) {
          foundVarValue = rawPayload.substring(
            indexOfVarBegin + 6,
            indexOfVarEnd
          );
          foundVarValue = this.translateValue(foundVarValue);
          rawPayload =
            rawPayload.substring(0, indexOfVarBegin) +
            foundVarValue +
            rawPayload.substring(indexOfVarEnd + 7);
        }
      } while (indexOfVarBegin !== -1 && indexOfVarEnd !== -1);

      return rawPayload;
    },
    translateValue(varValue) {
      if (varValue === "invoiceNumber") {
        return this.order.invoiceNumber;
      }
      return varValue;
    }
  },
  mounted() {
    this.form.orderId = this.order.id;
    this.form.clientId = this.order.client.id;
  },
  computed: {
    ...mapGetters(["products", "variables"]),
    getBaseURL() {
      return baseURL;
    },
    getMailTemplateContent() {
      return _.find(this.variables, function(o) {
        return o.code === "MAIL_TEMPLATE_CONTENT";
      });
    },
    getMailTemplateTitle() {
      return _.find(this.variables, function(o) {
        return o.code === "MAIL_TEMPLATE_TITLE";
      });
    },
    filePath() {
      return this.order.filePath;
    }
  }
};
</script>
