<template>
  <b-form-group label="Produkty" label-for="product">
    <b-form-select
      multiple
      id="product"
      v-model="productsLocal"
      :options="productsList"
    >
    </b-form-select>
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_LIST } from "@/core/services/store/user.module";

export default {
  name: "ChooseProducts",
  props: ["p"],
  model: {
    prop: "p",
    event: "productsChange"
  },
  mounted() {
    this.$store.dispatch(USER_LIST);
  },
  computed: {
    ...mapGetters(["productsAll"]),
    productsList() {
      let result = [];
      this.productsAll.forEach(product => {
        result.push({
          value: product.id,
          text: product.name + " (" + product.category + ")"
        });
      });
      return result;
    },
    productsLocal: {
      get: function() {
        return this.p;
      },
      set: function(value) {
        this.$emit("productsChange", value);
      }
    }
  }
};
</script>

<style scoped></style>
